import { Drawer, Flex, ScrollArea } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { FC, useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { InlineAuthView } from '~/components/auth/InlineAuthView'
import type { ServicesForm, StepName } from '~/components/buyer/service-details/ServiceDetails'
import {
  PackageField,
  PackageType,
  packageLabel,
} from '~/components/seller/service-setup/PackageFields'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { getImageUrl } from '~/components/shared/image/helpers'
import { notify } from '~/components/shared/notify'
import { Typography } from '~/components/shared/text/Typography'
import {
  CreateServiceSessionRequest,
  ProfileSummaryType,
  Question,
  SellerService,
  ServiceType,
} from '~/endpoints/model'
import { createStripeServicePaymentSession } from '~/endpoints/payment'
import { useAuth, useScreenSize } from '~/hooks'
import { getConsumerClient } from '~/utils/onsched'
import { Payment } from '~/utils/payments'
import getStripe from '~/utils/stripeJsLoader'

import AnswerQuestionsStep from './AnswerQuestionsStep'
import OrderSummaryStep from './OrderSummaryStep'
import ScheduleStep from './ScheduleStep'
import { useAnalytics } from '~/context'

export interface OrderServiceDrawerProps {
  form: UseFormReturnType<ServicesForm>
  user: ProfileSummaryType
  service: SellerService
  packageType: PackageType
  opened: boolean
  daysAmount: number
  onClose: () => void
}

const OrderServiceDrawer: FC<OrderServiceDrawerProps> = ({
  form,
  daysAmount,
  service,
  packageType,
  opened,
  user,
  onClose,
}) => {
  const { isMobileScreen } = useScreenSize()
  const [isLoading, setLoading] = useState(false)
  const selectedPackage = service[packageType] as PackageField
  const scheduled = service.type === ServiceType.consultation
  const { id: authUserId } = useAuth()
  const [showInlineAuth, setShowInlineAuth] = useState(false)
  const thatchSegment = useAnalytics()

  const setStep = useCallback(
    function setStep(name: StepName) {
      form.setFieldValue('step', name)
    },
    [form]
  )

  const tabItems = useMemo(() => {
    const requiredItems = [
      { name: 'Preferences', onClick: () => setStep('Preferences') },
      { name: 'Confirm Order', onClick: () => setStep('Confirm Order'), finalise: true },
      { name: 'Pay', onClick: () => setStep('Pay') },
    ]
    if (form.values.scheduled) {
      return [{ name: 'Schedule', onClick: () => setStep('Schedule') }, ...requiredItems]
    }
    return requiredItems
  }, [form.values.scheduled, setStep])

  const isItinerary = service.type === ServiceType.itinerary

  const payment = new Payment(
    Number(service[packageType]?.price),
    daysAmount,
    isItinerary ? 0.5 : 1.0
  )

  const isClickable = useCallback(
    (stepNameIndex: number) => {
      const currentPageIndex = tabItems.findIndex(tab => tab.name === form.values.step)
      return stepNameIndex < currentPageIndex
    },
    [tabItems, form.values.step]
  )

  const title = useMemo(
    () => (
      <Flex gap={isMobileScreen ? 4 : 8}>
        {tabItems.map((item, idx) => (
          <Flex
            key={`${item.name}-${idx}`}
            onClick={isClickable(idx) ? item.onClick : undefined}
            gap={isMobileScreen ? 4 : 8}
          >
            {!!idx && (
              <Typography
                variant={
                  isMobileScreen
                    ? 'caption'
                    : form.values.step === item.name
                    ? 'button_small'
                    : 'body3'
                }
                color="appPlaceholder.0"
              >
                {'>'}
              </Typography>
            )}
            <Typography
              variant={
                isMobileScreen
                  ? form.values.step === item.name
                    ? 'captionBold'
                    : 'caption'
                  : form.values.step === item.name
                  ? 'button_small'
                  : 'body3'
              }
              color={form.values.step === item.name ? 'appBlack.0' : 'appPlaceholder.0'}
              sx={{
                cursor: isClickable(idx) ? 'pointer' : 'default',
              }}
            >
              {item.name}
            </Typography>
          </Flex>
        ))}
      </Flex>
    ),
    [form.values.step, isClickable, tabItems]
  )

  const confirmBtnLabel =
    form.values.step === 'Confirm Order'
      ? `Continue ($${payment.due.total.toFixed(2)})`
      : 'Continue →'

  const handleSubmit = async () => {
    const { step, scheduledTime, questions } = form.values

    console.debug({ step })

    const currentPageIndex = tabItems.findIndex(tab => tab.name === form.values.step)
    const tab = tabItems[currentPageIndex]

    if (tab.finalise) {
      if (!authUserId) {
        const value = JSON.stringify(form.values)
        console.info('Saving form state to local storage: %s', value)
        localStorage.setItem(form.values.key, value)
        setShowInlineAuth(true)
        return
      }
      try {
        setLoading(true)
        console.debug('Handling final form step: %o', tab)

        

        const getDescription = () => {
          const type = packageLabel[packageType]
          const name = `${user.firstName} ${user.lastName}`
          switch (service.type) {
            case ServiceType.consultation:
              return `${type} | ${selectedPackage.duration} minute consultation call with ${name}`
            case ServiceType.itinerary:
              return `${type} | Custom Itinerary by ${name}. (50% deposit)`
            default:
              return `${type} | ${selectedPackage.recs} Curated Recs by ${name}.`
          }
        }

        let bookingId = undefined

        if (scheduled && scheduledTime) {
          console.debug('Creating appointment in onsched')
          const appointment = await getConsumerClient()
            .v1AppointmentsCreate({
              serviceId: selectedPackage.service,
              resourceId: scheduledTime.resourceId,
              locationId: user.locationId,
              startDateTime: scheduledTime.startDateTime ?? undefined,
              endDateTime: scheduledTime.endDateTime ?? undefined,
              notes: `To reschedule this appointment send an email request to ${user.name} at: ${user.email}`,
            })
            .then(it => it.data)
          console.debug('Appointment: %o', appointment)
          bookingId = appointment.id ?? undefined
        }

        const txId = uuidv4()
        const baseUrl = `${window.location.protocol}//${window.location.host}`
        const successUrl = `${baseUrl}/seller/services/${service.type}/order-success?sessionid={CHECKOUT_SESSION_ID}`
        const cancelUrl = `${baseUrl}${window.location.pathname}?sessionid={CHECKOUT_SESSION_ID}`

        const payload: CreateServiceSessionRequest = {
          seller: user.uid,
          txId,
          successURL: successUrl,
          cancelURL: cancelUrl,
          service: service.id,
          type: service.type,
          tier: packageType,
          image:
            getImageUrl({ src: user.sellerPhoto }) ??
            'https://uploads-ssl.webflow.com/610acfb4aed44c21ee2e736f/61296459e31039da27c688bf_thatch_link_preview.png',
          amount: payment.due.subtotal,
          fee: payment.due.fee,
          description: getDescription(),
          questions,
          delivery: selectedPackage.delivery ?? undefined,
          duration:
            service.type === ServiceType.itinerary
              ? form.values.days
              : selectedPackage.duration ?? undefined,
          recs: selectedPackage.recs ?? undefined,
          locationId: user.locationId ?? undefined,
          resourceId: user.resourceId ?? undefined,
          date: scheduledTime?.date ?? undefined,
          time: scheduledTime?.time,
          timeLabel: scheduledTime?.displayTime ?? undefined,
          bookingId,
        }

        thatchSegment.trackMiscEvent('service_paywall_clicked', {
          service_id: service.id,
          service_type: service.type,
          tier: packageType,
          price: payment.due.subtotal,
          fee: payment.due.fee,
          description: getDescription(),
          questions,
          delivery: selectedPackage.delivery,
          duration: selectedPackage.duration,
          recs: selectedPackage.recs,
          locationId: user.locationId,
          resourceId: user.resourceId,
          date: scheduledTime?.date,
          time: scheduledTime?.time,
          timeLabel: scheduledTime?.displayTime,
          bookingId,
          seller_username: user.username,
          seller_token: user.uid,
          seller_name: user.name,
        });

        try {
          const sessionResp = await createStripeServicePaymentSession(payload)
          const stripe = await getStripe()
          const { error } = (await stripe?.redirectToCheckout({
            sessionId: sessionResp.id,
          })) ?? { error: Error('Stripe not found') }
          if (error) {
            notify(true, 'Unable to load Stripe')
          }
        } catch (e) {
          console.error(e)
          notify(true, e.message)
          if (bookingId) {
            console.warn('Rolling back booking %s', bookingId)
            await getConsumerClient().v1AppointmentsDelete(bookingId)
          }
        }
      } catch (err) {
        notify(true, 'Unable to load Stripe')
      } finally {
        setLoading(false)
      }
    } else if (currentPageIndex !== tabItems.length - 1) {
      setStep(tabItems[currentPageIndex + 1].name as StepName)
    }
  }

  const isSelected = (value: StepName) => form.values.step === value

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={title}
      position="right"
      size={544}
      scrollAreaComponent={ScrollArea.Autosize}
      styles={{
        body: {
          paddingBottom: 0,
          paddingLeft: isMobileScreen ? 16 : 24,
          paddingRight: isMobileScreen ? 16 : 24,
        },
        root: { zIndex: 2023, position: 'fixed' },
        header: { borderBottom: '1px solid rgba(0, 0, 0, 0.25)', padding: '20px 24px' },
        title: { py: 20 },
      }}
      closeButtonProps={{ iconSize: 30 }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex
          direction="column"
          mih="calc(100vh - 63px)"
        >
          {isSelected('Schedule') && (
            <ScheduleStep
              user={user}
              form={form}
              selectedPackage={selectedPackage}
            />
          )}

          {isSelected('Preferences') && user && service.type && (
            <AnswerQuestionsStep
              form={form}
              questions={(user as any)[service.type].questions as Question[]}
            />
          )}

          {isSelected('Confirm Order') && (
            <OrderSummaryStep
              form={form}
              daysAmount={daysAmount}
              service={service}
              packageType={packageType}
              payment={payment}
            />
          )}

          <Flex
            bg="appWhite.0"
            direction="column"
            gap={isMobileScreen ? 8 : 16}
            sx={{
              position: 'sticky',
              borderTop: '1px solid rgba(0, 0, 0, 0.25)',
              padding: isMobileScreen ? '16px 0px' : '24px 48px',
              bottom: 0,
            }}
          >
            <ThatchButton
              loading={isLoading}
              type="submit"
              label={confirmBtnLabel}
              size="medium"
              sx={{ width: '100%' }}
            />
            {form.values.step === 'Confirm Order' && (
              <Typography
                variant={isMobileScreen ? 'caption' : 'body2'}
                color="appPlaceholder.0"
                align="center"
              >
                You won’t be charged yet
              </Typography>
            )}
          </Flex>
        </Flex>
      </form>

      {showInlineAuth && (
        <InlineAuthView
          isInlinePurchase={true}
          launchSource="buy_service"
          onClose={() => setShowInlineAuth(false)}
          isOpened={showInlineAuth}
          initLandingView="signup"
          onEmailAuthSuccess={async () => {
            setShowInlineAuth(false)
            localStorage.removeItem(form.values.key)
          }}
        />
      )}
    </Drawer>
  )
}

export default OrderServiceDrawer
